import React, { Component } from 'react'
import { Container,Typography,Card,Grid,Divider,TextField,Button,Box, Toolbar} from '@mui/material';
import bg from "../src/img/bg.jpg"
export class Schlorship extends Component {
  render() {
    return (
      <div>
      <Box sx={{backgroundImage:`url(${bg})`,backgroundPosition:'center',height:600, backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',}}>
<Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},mt:3}}>
 <Container maxWidth='md'>
 <Toolbar/>
 <Box sx={{display:'flex',justifyContent:'center',height:600,mt:4}}>
 <Box sx={{borderRadius:2,backgroundColor:'white',height:400,display:'flex',flexDirection:'column',width:'100%'}}>
 <Box sx={{display:'flex',flexDirection:'column',marginLeft:{xs:'0',sm:'0',md:'15%',},marginRight:{xs:'0',sm:'0',md:'15%',}}}>
 <br/>
 <Typography align='center' sx={{m:2}}> Form </Typography>
 <TextField onChange={this.handlechange} id="outlined-basic" label="Explain about yourself" name='type' variant="outlined"  size='small' sx={{m:1,fontSize:10}}/>
<TextField  onChange={this.handlechange} id="outlined-basic" label="Enter name" name='name' variant="outlined"  size='small' sx={{m:1,fontSize:10}}/>
 <TextField  onChange={this.handlechange} id="outlined-basic" label="Enter email id" name='email' variant="outlined"  size='small' sx={{m:1}}/>
 
 <TextField onChange={this.handlechange} id="outlined-basic" label="Enter contact no " name='contactno' variant="outlined"  size='small' sx={{m:1}}/>
<br/>

{/*
this.state.circle?<Box sx={{display:'flex',justifyContent:'center'}}><CircularProgress size={24} /> </Box>:null
    */}

<br/>
 <Button variant="contained" sx={{width:200,backgroundColor:'#c7167a'}} onClick={()=>{

if(this.state.contactno!==""||this.state.email!==""||this.state.name!==""||this.state.type)

{
/*


console.log(this.state.type,this.state.contactno,this.state.email,this.state.name)
this.setState({circle:true})

   const key= firebase.database().ref("contactform").push().key
   firebase.database().ref("contactform").child(key).set({
 name:this.state.name,
 contactno:this.state.contactno,
 email:this.state.email,
 type:this.state.type,
 key:key,
   }).then((d)=>{
    
   //  this.setState({paragraph:[],paragraphhead:""})
       alert("data successfully placed");
       this.setState({circle:false})
       window.location.reload()
   })


}else{
alert("All fields are complessary")

*/

}




 }}>Submit</Button>


 
 </Box> 
   



   </Box>
 </Box>
 </Container>
      </Box>
      </Box>
      
      
      
      
      
      
      </div>
    )
  }
}

export default Schlorship




