import { Container, Box, Paper, Typography, Grid, Grid2 } from "@mui/material";
import React, { Component } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Scroller from './Scroller'

export class Nseindices extends Component {
  render() {
    return (
      <div>

        <Box
          sx={{
            minHeight: 80,
            backgroundColor: "#e8e8e8",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
            <Container maxWidth='xl'>
  
  <Box sx={{  display:'flex', flexWrap:'nowrap', overflowX:{xs:'scroll',sm:'scroll',md:'hidden'} }}>


            <Box
              sx={{
                height: 90,
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
               // maxWidth: "100%",
               // overflowX: "scroll",
              }}
            >
              {this.props.nsedata?this.props.nsedata.splice(0, 5).map((row,i) => (
                <Paper
                  elevation={0}
                  sx={{
                    height: 70,
                    width: 170,
                    borderWidth: 1,
                    borderRadius: 1,
                    borderColor: "blue",
                    backgroundColor: "#fff",
                    mt: 0.2,
                    mr: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: "bold",
                      color: "#07497b",
                      mt: 0.3,
                      ml: 1,
                    }}
                  >
                   {row.name}
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: "600", ml: 1 ,display:'flex',flexDirection:'row'}}>
                    {row.last_trade} <ArrowDropDownIcon style={{ mt: 2 ,display:row.change_num < 0 ? 'flex' :'none'}} />  <ArrowDropUpIcon style={{ mt: 2,display:row.change_num > 0 ?'flex' :'none'}} /> 
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 12,
                      fontWeight: "bold",
                      color: "#07497b",
                      mt: 0.1,
                      ml: 1,
                    }}
                  >
                    {row.change_num} ({row.change_percent}%)
                  </Typography>
                </Paper>
              )):[]}
            </Box>



</Box>


            </Container>
        </Box>


<br/>
 <Container maxWidth='xl'> 
<Box>
<Grid2 container columnSpacing={4} rowSpacing={4}> 
<Grid2  size={{ xs: 12, md: 6 }}>
<Box sx={{maxHeight:520, backgroundColor:'#fff',border:1,borderColor:'#f0f0f0',borderRadius:2}}>

<TableContainer component={Box} sx={{maxHeight:520}} >
      <Table sx={{ minWidth: 520 }} size="small" aria-label="a dense table">
        <TableHead sx={{backgroundColor:'#07497b'}}>
          <TableRow>
            <TableCell sx={{color:'#fff'}}>Share</TableCell>
            <TableCell align="center" sx={{color:'#fff'}}>Price</TableCell>
            <TableCell align="center"  sx={{color:'#fff'}}>Increase(%)</TableCell>
            <TableCell align="right" sx={{color:'#fff'}}>Prev close</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.nsedata?this.props.nsedata.map((row,i) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 },backgroundColor:i % 2?'#f8f8f8':'#fff' }}
            >
              <TableCell component="th" scope="row" sx={{fontSize:13}}>
             {row.name}
              </TableCell>
              <TableCell align="center" sx={{fontSize:13}}>{row.change_num}</TableCell>
              <TableCell align="center" sx={{fontSize:13}}>{row.change_percent}%</TableCell>
              <TableCell align="right" sx={{fontSize:13}}>{row.last_trade}</TableCell>
            </TableRow>
          )):[]}
        </TableBody>
      </Table>
    </TableContainer>

</Box>
</Grid2>
<Grid2 size={{ xs: 12, md: 6 }}>
<Box sx={{minHeight:220,width:'100%', backgroundColor:'#fff'}}>

<Scroller/>

</Box>
</Grid2>
</Grid2>
</Box>
</Container>








      </div>
    );
  }
}

export default Nseindices;
