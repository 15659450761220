
import React, { Component } from 'react'
import poo from "../src/img/pp.jpg"
import { Paper,Box,Link } from '@mui/material'
import { Container,Typography,Card,Grid,Divider,TextField,Button} from '@mui/material';

export class Community extends Component {
  render() {
    return (
      <div>    
        <br/>
        <br/>
        
          <Box sx={{backgroundImage:{xs:`url(${poo})`,sm:`url(${poo})`},width:'100%',display:'flex',height:{xs:200,sm:250,md:310},backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',}}>
      <br/>
      <br/>
      <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},mt:3,width:'100%'}}>
      <Container maxWidth='xl'>
      <Box sx={{background:'rgba(28,68,106,.7)',minHeight:{xs:140,sm:180,md:200}, width:'100%',mt:1,}}>
      <Box sx={{mt:2}}>
      <br/>
      <Typography
                  align="justify"
                  sx={{
                    m: 1,
                    mb: 2,
                    mt:2,
                    fontWeight: "335",
                    fontFamily: "Roboto Slab",
                    textAlign : 'center',
                    color: "#fff",
                    fontSize: { xs: 13, sm: 13, md: 15, lg: 15 },
                  }}
                >
 Community support refers to the assistance and resources provided by individuals, organizations, or businesses within a community to help its members. This support can take many forms, such as volunteering, donating to local charities, or participating in community events.
                </Typography>
      </Box>


 


      </Box>
      </Container>
      </Box> 
 </Box>
</div>
    )
  }
}

export default Community