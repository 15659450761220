import React, { Component } from "react";
import { Box, Container, Divider, Paper, Typography } from "@mui/material";
import Marquee from "react-fast-marquee";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Nseindices from "./LandingPages/Nseindices";
import Scroller from "./Scroller";
import map2 from "./img/map2.png";
import base_url from "./base_url";
import Bseindices from "./LandingPages/Bseindices";
import LossmakingShares from "./LandingPages/LossmakingShares";
import ProfitMakingShares from "./LandingPages/ProfitMakingShares";

export class Landingpage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "one",

      bse: [],
      nse: [],
      forex: [],
      topLoser: [],
      topgainer: [],

      is_visible: true,
    };
  }



  componentDidMount() {
    this.retriveData();
    this.interval = setInterval(() => this.retriveData(), 6000);
  }




  retriveData = () => {
    fetch(`${base_url.base_url}/websiteDashboardData`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        this.setState({
          is_visible: false,
          bse: result.bse,
          nse: result.nse,
          forex: result.forex,
          topLoser: result.topLoser,
          topgainer: result.topgainer,
        });
      });


  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div>
        <Box
          sx={{
            height: 36,
            width: "100%",
            backgroundColor: "#ece8ff",
            display: "flex",
            justifyContent: "center",
            mt: 7,
          }}
        >
          <Marquee>
            {this.state.topgainer.map((e,i) => (
              <Box sx={{ marginRight: 3, display: "flex" }}>
                <Typography
                  sx={{ fontSize: 12, color: "#07497b", display: "flex",textTransform:'uppercase' }}
                >
                  {e.company} 
                  <Typography sx={{ fontSize: 12, color: "#19b400", ml: 0.5 }}>
                | {e.last_trade} |
                  </Typography>
                  <ArrowDropUpIcon style={{ mb: 2 }} />({e.change_percent}%){" "}
                </Typography>
              </Box>
            ))}
          </Marquee>
        </Box>

        <Box
          sx={{
            width: "100%",
            backgroundColor: "#07497b",
            display: "flex",
            justifyContent: "left",
          }}
        >
          <Container maxWidth="xl">
            <Tabs
              scrollButtons="auto"
              value={this.state.value}
              onChange={this.handleChange}
              aria-label="wrapped label tabs example"
            >
              <Tab
                value="one"
                label="NSE Indices"
                onClick={() => this.setState({ value: "one" })}
                sx={{ fontSize: 12, fontWeight: "bold", color: "#fff" }}
                wrapped
              />
              <Tab
                value="two"
                onClick={() => this.setState({ value: "two" })}
                sx={{ fontSize: 12, fontWeight: "bold", color: "#fff" }}
                label="BSE Indices"
              />
              <Tab
                value="three"
                onClick={() => this.setState({ value: "three" })}
                sx={{ fontSize: 12, fontWeight: "bold", color: "#fff" }}
                label="Loss making shares"
              />
              <Tab
                value="four"
                onClick={() => this.setState({ value: "four" })}
                sx={{ fontSize: 12, fontWeight: "bold", color: "#fff" }}
                label="Profit making share"
              />
            </Tabs>
          </Container>
        </Box>

        <Divider />

        <Box sx={{ minHeight: 400 }}>
          {
              this.state.value=='one'? <Nseindices nsedata = {this.state.nse} /> :this.state.value=='two'? <Bseindices nsedata={this.state.bse} /> : this.state.value=='three'?<LossmakingShares nsedata={this.state.topLoser} /> : this.state.value=='four'?<ProfitMakingShares nsedata={this.state.topgainer}/> :  <Nseindices nsedata = {this.state.nse} />
          }
         
        </Box>

        <Box sx={{ minHeight: 300, width: "100%", backgroundColor: "#fff" }}>
          <Container maxWidth="xl">
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: 20, sm: 22, md: 25, lg: 25 },
                fontWeight: "600",
                mt: 2,
                ml: 2,
                mt: 2,
              }}
            >
              Our Customers
            </Typography>
            <Box sx={{ backgroundColor: "#f5f3f3" }}>
              <Scroller />
            </Box>

            <br />

            <Box
              sx={{
                backgroundImage: `url(${map2})`,
                minHeight: 300,
                backgroundColor: "#022246 ",
                opacity: 0.9,
              }}
            >
              <Box
                sx={{ ml: { xs: "0%", sm: "2%" }, mr: { xs: "0%", sm: "2%" } }}
              >
                <br />
                <Typography
                  align="justify"
                  variant="h1"
                  sx={{
                    m: 1,
                    mb: 2,
                    fontWeight: "545",
                    fontFamily: "sans-serif",
                    color: "white",
                    fontSize: { xs: 22, sm: 22, md: 30, lg: 30 },
                  }}
                >
                  Who we are ?.
                </Typography>
                <Divider />
                <Typography
                  align="justify"
                  sx={{
                    m: 1,
                    mb: 2,
                    fontWeight: "335",
                    fontFamily: "Roboto Slab",
                    color: "#fff",
                    fontSize: { xs: 13, sm: 13, md: 15, lg: 15 },
                  }}
                >
                  A company listed on the stock market offers shares for public
                  purchase. These shares represent ownership in the company.
                  Investors buy and sell shares, hoping for price appreciation
                  or dividends. The stock market is influenced by economic
                  factors, company performance, and market sentiment.
                </Typography>
                <Typography
                  align="justify"
                  sx={{
                    m: 1,
                    mb: 2,
                    fontWeight: "335",
                    fontFamily: "Roboto Slab",
                    color: "#fff",
                    fontSize: { xs: 13, sm: 13, md: 15, lg: 15 },
                  }}
                >
                  A company listed on the stock market offers shares for public
                  purchase. These shares represent ownership in the company.
                  Investors buy and sell shares, hoping for price appreciation
                  or dividends. The stock market is influenced by economic
                  factors, company performance, and market sentiment.
                </Typography>
                <Typography
                  align="justify"
                  sx={{
                    m: 1,
                    mb: 2,
                    fontWeight: "335",
                    fontFamily: "Roboto Slab",
                    color: "#fff",
                    fontSize: { xs: 13, sm: 13, md: 15, lg: 15 },
                  }}
                >
                  Shares are generally highly liquid, meaning you can buy and
                  sell them easily
                </Typography>
                <br />
              </Box>
            </Box>
          </Container>
        </Box>
      </div>
    );
  }
}

export default Landingpage;
