import React, { Component } from 'react'
import { Container,Typography,Card,Grid,Divider,TextField,Button,Box} from '@mui/material';
export class Media extends Component {
  render() {
    return (
      <div>
      <Box sx={{backgroundColor:'#e1e9e9',minHeight:450}}>
<Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},mt:3}}>
 <Container maxWidth='xl'>
 
 
 
 
 </Container>
      </Box>
      </Box>
      
      
      
      
      
      
      </div>
    )
  }
}

export default Media