import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import one from "../img/1.jpg"
import two from "../img/2.jpg"
function Scroller() {
  return (
    <Carousel>
      <Carousel.Item>
      <img
      className="d-block w-100"
      src={one}
      alt="Second slide"
      style={{width:'100%'}}
    />
      </Carousel.Item>

<Carousel.Item>
      <img
      className="d-block w-100"
      src={one}
      alt="Second slide"
      style={{width:'100%'}}
    />
      </Carousel.Item>

<Carousel.Item>
      <img
      className="d-block w-100"
      src={one}
      alt="Second slide"
      style={{width:'100%'}}
    />
       
      </Carousel.Item>
  
    </Carousel>
  );
}

export default Scroller;